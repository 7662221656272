
import { defineComponent } from "vue";
import Course from "@/components/Course.vue";
import {
  Campus,
  Course as CourseType,
  Department,
  Professor,
  Room,
  Semester,
} from "@/types";

export default defineComponent({
  components: {
    Course,
  },
  computed: {
    courses(): CourseType[] {
      return this.$store.state.courses;
    },
    professors(): Professor[] {
      return this.$store.state.professors;
    },
    semesters(): Semester[] {
      return this.$store.state.semesters;
    },
    campus(): Campus[] {
      return this.$store.state.campus;
    },
    departments(): Department[] {
      return this.$store.state.departments;
    },
    rooms(): Room[] {
      return this.$store.state.rooms;
    },
  },
  methods: {
    refresh(e: InputEvent) {
      if (e.currentTarget) {
        let target = e.currentTarget as HTMLElement;
        let params = "?limit=100";

        target.querySelectorAll("input").forEach((e) => {
          if (e.value) {
            params += `&${e.name}=${e.value}`;
          }
        });

        target.querySelectorAll("select").forEach((e) => {
          if (e.value && e.value != "null") {
            params += `&${e.name}=${e.value}`;
          }
        });

        this.$store.dispatch("refreshCourses", params);
      }
    },
    clear() {
      this.$store.dispatch("clearCourses");
    },
  },
});
